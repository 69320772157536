import React, { Component } from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Services from '@common/Services';
import Footer from '@sections/Footer';

class ServicesNav extends Component {
  render() {
    return (
      <Layout>
        <Navbar />
        <Services />
        <Footer />
      </Layout>
    );
  }
}

export default ServicesNav;
